import { match } from "path-to-regexp";

export const protectedPages = [
  "/my-courses/*path",
  "/teacher/course/*path",
  "/my-sessions",
  "/teacher/courses",
  "/user/my-account",
  "/teacher/bundle/bundle-edit",
  "/teacher/course/create",
  "/teacher/courses/course-edit",
  "/teacher/courses/video-edit",
  "/teacher/courses/bootcamp-edit",
  "/teacher/courses/live-sessions-edit",
  "/teacher/course/upload-course-video",
  "/teacher/dashboard",
  "/teacher/files",
];

export const protectedApiRoutes = [
  "/api/v1/auth/account",
  "/api/v1/bundle/new",
  "/api/v1/bundle/my-bundles",
  "/api/v1/bundle/remove",
  "/api/v1/bundle/purchased/:path",
  "/api/v1/courses/course/new",
  "/api/v1/courses/course/new-live-session",
  "/api/v1/courses/course/remove",
  "/api/v1/courses/course/update",
  "/api/v1/courses/course/upload-signature",
  "/api/v1/courses/course/video-upload",
  "/api/v1/courses/enrolled",
  "/api/v1/courses/enrolled/videos",
  "/api/v1/courses/video",
  "/api/v1/stripe/courses/create-payment-intent",
  "/api/v1/stripe/bootcamps/create-payment-intent",
  "/api/v1/stripe/bundles/create-payment-intent",
  "/api/v1/stripe/sessions/create-payment-intent",
  "/api/v1/stripe/sessions/create-payment-intent-live-session",
  "/api/v1/stripe/sessions/create-payment-intent-guest",
  "/api/v1/stripe/tips/create-payment-intent",
  "/api/v1/imagekit/auth",
  "/api/v1/courses/my-courses",
  "/api/v1/jitsi/generate-token",
  "/api/v1/reviews/new",
  "/api/v1/sessions",
  "/api/v1/sessions/enrolled/:path",
  "/api/v1/stripe/teacher/account-link",
  "/api/v1/stripe/teacher/account-dashboard",
  "/api/v1/stripe/teacher/account-status-update",
  "/api/v1/user/apply",
  "/api/v1/user/availability",
  "/api/v1/user/edit-profile",
  "/api/v1/user/settings",
  "/api/v1/user/contacts",
  "/api/v1/user/mentorship/new",
  "/api/v1/stream/token-provider",
];

export const originalResponseRoutes = [
  "/api/v1/auth/ocis-code",
  "/api/v1/auth/ocis-signin",
  "/api/v1/conference",
  "/api/v1/conference/:path",
  "/api/v1/courses/course/upload-signature",
  "/api/v1/jitsi/generate-token",
  "/api/v1/oidc/userinfo",
  "/api/sitemap",
  "/api/sitemap",
  "/api/auth/:path",
  "/api/auth/*path",
];

export function isPathMatched(path, patterns) {
  const basePath = path.split("?")[0]; // Extracts the base URL without the query string for matching.
  for (let pattern of patterns) {
    const matcher = match(pattern);
    if (matcher(basePath)) {
      return true;
    }
  }
  return false;
}
