import { ReactNode } from "react";
import styled from "styled-components";

export const StyledInput = styled.input<{
  $inputFontSize: string;
  $inputHeight: string;
  $inputBorderColor: string;
  $inputFontColor: string;
  $inputBackgroundColor: string;
  $leftIcon?: ReactNode;
  $iconBoxSize: string | number;
}>`
  font-size: ${({ $inputFontSize }) => $inputFontSize};
  padding: ${({ $leftIcon, $iconBoxSize }) => ($leftIcon ? `10px ${$iconBoxSize}` : "10px 20px")};
  height: ${({ $inputHeight }) => $inputHeight};
  border: none;
  border-radius: 1.3em;
  color: ${({ $inputFontColor }) => $inputFontColor};
  background-color: ${({ $inputBackgroundColor }) => $inputBackgroundColor};
  width: 100%;
  padding-left: 35px;
  padding-right: 25px;
  &:focus {
    outline: none;
    background-color: #ffffff;
    box-shadow: 0.05rem 0.05rem 0.15rem 0rem rgba(33, 40, 50, 0.5) !important;
  }
`;

export const StyledIconContainer = styled.span<{
  $inputHeight: string;
  $iconBoxSize: string | number;
}>`
  height: ${({ $inputHeight }) => $inputHeight};
  width: ${({ $iconBoxSize }) => $iconBoxSize};
  position: absolute;
  left: 5px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInputContainer = styled.span`
  position: relative;
`;
