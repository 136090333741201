export enum SessionStatus {
  PENDING = "Pending",
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
  READY = "Ready",
  INPROGRESS = "In Progress",
}

export enum StripePayoutAccountStatus {
  PENDING = "Pending Requirements",
  VERIFIED = "Verified",
}

export enum UserRole {
  STUDENT = "student",
  ADMIN = "admin",
  TEACHER = "teacher",
}
