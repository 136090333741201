import React from "react";
import Link from "next/link";
import Image from "next/image";

const Custom500 = () => {
  return (
    <div className="error-area ptb-100">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="error-content">
              <Image width={500} height={230} src="/images/internal-server-error.jpg" alt="image" />
              <h3>Something went wrong 😕</h3>
              <p className="mt-4">We are fixing it. Please try again in a few hours.</p>
              <div className="btn-box">
                <Link legacyBehavior href="/">
                  <a className="default-btn">
                    Homepage <span></span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom500;
