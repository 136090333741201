import React, { useState, useRef } from "react";
import Link from "@/components/Links/ActiveLink";
import SearchForm from "./SearchForm";
import useSWR from "swr";
import baseUrl, { ocisUrl } from "@/utils/baseUrl";
import { Avatar } from "@mui/material";
import { signOut } from "next-auth/react";
import { UserRole } from "@/lib/shared/enums";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const Navbar = ({ user }) => {
  const [menu, setMenu] = useState(false);
  const [isAvatarClicked, setIsAvatarClicked] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        toggleNavbar();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const stringAvatar = (name) => ({
    sx: {
      ...styles.avatarStyle,
      boxShadow: isAvatarClicked ? "0px 0px 1px 4px #e9e9e9" : "none",
    },
    children: `${name.charAt(0)}${name.charAt(name.indexOf(" ") + 1 || 1)}`,
  });

  const toggleClass = (className, style) => {
    const element = document.getElementsByClassName(className)[0];
    if (element) element.style.cssText = style;
  };

  const toggleNavbar = () => {
    toggleClass("dropdown-menu user-options", "opacity: 0; visibility: hidden;");
    toggleClass("dropdown-menu categories", "display: none;");
    setIsAvatarClicked(false);
    setMenu(false);
  };

  const toggleMenu = () => {
    const style = menu ? "opacity: 0; visibility: hidden;" : "opacity: 1; visibility: visible;";
    toggleClass("dropdown-menu user-options", style);
    setIsAvatarClicked(!menu);
    setMenu(!menu);
  };

  const { data } = useSWR(`${baseUrl}/api/v1/search-autocomplete`, fetcher);

  const courses = data ? data.courses : [];
  const teachers = data ? data.teachers : [];

  const isAdmin = user?.role === UserRole.ADMIN;
  const isTeacher = user?.role === UserRole.TEACHER;
  const isStudent = user?.role === UserRole.STUDENT;

  return (
    <div id="navbar" className="navbar-area">
      <div className="edemy-nav">
        <div className="container-fluid">
          <div className="navbar navbar-expand-lg navbar-light">
            <div style={styles.alignItemsBaseline} className="d-flex">
              <div className="logo-icon">
                <Link legacyBehavior href="/">
                  <a style={styles.cursorPointer} onClick={toggleNavbar} className="navbar-brand">
                    <img width={29} height={29} src={"/icons/apple-touch-icon.png"} alt="logo" />
                  </a>
                </Link>
              </div>
              {courses && teachers && <SearchForm courses={courses} teachers={teachers} />}
            </div>
            <div className="others-option d-flex align-items-center">
              <div className="option-item">
                {user?.name ? (
                  <div className="user-dropdown">
                    <button ref={buttonRef} onClick={toggleMenu} style={styles.avatarBtn} className="user-btn">
                      <Avatar {...stringAvatar(user.name)} src={user.profilePhoto} />
                    </button>

                    <ul ref={dropdownRef} className="dropdown-menu user-options">
                      <div className="user-info">
                        <div className="user-row">
                          <img src={user.profilePhoto} alt="User Image" className="user-image" />
                          <div className="user-details">
                            <span className="user-name">{user.name}</span>
                            <span className="user-handle">@{user.profileId}</span>
                          </div>
                        </div>
                        <div className="application-row">
                          {isStudent &&
                            (user.asTeacherApply ? (
                              <span className="application-status pending">Reviewing your application</span>
                            ) : (
                              <Link prefetch={false} legacyBehavior href="/become-a-teacher">
                                <a onClick={toggleNavbar} className="apply-link">
                                  Apply as instructor
                                </a>
                              </Link>
                            ))}
                        </div>
                      </div>

                      <li className="nav-item">
                        <Link legacyBehavior prefetch={false} href="/my-courses" activeClassName="active">
                          <a onClick={toggleNavbar} className="nav-link">
                            My Library
                            <i className="fi fi-rr-lesson"></i>
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link legacyBehavior prefetch={false} href="/my-sessions" activeClassName="active">
                          <a onClick={toggleNavbar} className="nav-link">
                            <i className="fi fi-rr-calendar"></i>Calendar
                          </a>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a onClick={toggleNavbar} target="_blank" rel="noopener noreferrer" href={ocisUrl}>
                          <span>Cloud Storage</span>
                          <i className="fi fi-rr-file-cloud"></i>
                        </a>
                      </li>

                      <li className="nav-item">
                        <Link legacyBehavior prefetch={false} href="/user/my-account" activeClassName="active">
                          <a onClick={toggleNavbar} className="nav-link">
                            <i className="fi fi-rr-user-pen"></i>Account
                          </a>
                        </Link>
                      </li>

                      {(isTeacher || isAdmin) && (
                        <li className="nav-item">
                          <Link prefetch={false} legacyBehavior href="/teacher/dashboard">
                            <a onClick={toggleNavbar} className="nav-link">
                              <i className="fi fi-rs-pencil-paintbrush"></i>Dashboard
                            </a>
                          </Link>
                        </li>
                      )}

                      {isAdmin && (
                        <li className="nav-item">
                          <Link prefetch={false} legacyBehavior href="/admin/dashboard">
                            <a onClick={toggleNavbar} className="nav-link">
                              <i className="fi fi-rr-users-gear"></i>Admin Dashboard
                            </a>
                          </Link>
                        </li>
                      )}

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          style={styles.cursorPointer}
                          onClick={(e) => {
                            e.preventDefault();
                            toggleNavbar();
                            signOut({ callbackUrl: `${baseUrl}/authentication` });
                          }}
                        >
                          <i className="fi fi-rr-sign-out-alt"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <Link legacyBehavior href="/authentication">
                    <a onClick={toggleNavbar} style={styles.loginLink} className="default-btn">
                      Login<span></span>
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const styles = {
  avatarBtn: {
    border: "none",
    background: "none",
  },
  loginLink: {
    marginBottom: "0",
    marginTop: "10px",
    color: "white",
  },
  avatarStyle: {
    bgcolor: "#e9e9e9",
    width: 46,
    height: 46,
  },
  alignItemsBaseline: {
    alignItems: "baseline",
  },
  cursorPointer: {
    cursor: "pointer",
  },
};
