// Import global styles
import "../styles/animate.min.css";
import "../styles/boxicons.min.css";
import "../styles/meanmenu.min.css";
import "../styles/NotFound.css";
import "../styles/flaticon.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "stream-chat-react/dist/css/v2/index.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import "../styles/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "flag-icons-svg/css/flag-icons.css";
import "react-modal-video/css/modal-video.css";
import "../styles/style.css";
import "../styles/responsive.css";

// Import necessary modules and components
import React from "react";
import Error from "pages/_error";
import Custom404 from "pages/404";
import Custom500 from "pages/500";
import Layout from "../components/_App/Layout";
import ReactModal from "react-modal";
import { SessionProvider } from "next-auth/react";

ReactModal.setAppElement("#root");

const MyApp = ({ Component, pageProps }) => {
  const { session, error, key, ...otherPageProps } = pageProps;

  const getLayout =
    Component.getLayout ||
    ((page) => {
      return (
        <Layout key={key} {...otherPageProps}>
          {page}
        </Layout>
      );
    });

  const renderPage = () => {
    if (error) {
      if (error.code === 404) {
        return getLayout(<Custom404 />);
      } else if (error.code === 500) {
        return getLayout(<Custom500 />);
      } else {
        return getLayout(<Error />);
      }
    }
    return getLayout(<Component {...otherPageProps} />);
  };

  return <SessionProvider session={session}>{renderPage()}</SessionProvider>;
};

export default MyApp;
