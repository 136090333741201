import Fuse from "fuse.js";
import React, { ChangeEvent, FC, ReactNode, useState, useRef, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Dropdown from "./dropdown";
import InputBox from "./input";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type Record = { item: { key: string; value: string } };

interface IProps {
  placeholder: string;
  data: { key: string; value: string }[];
  fuseConfigs?: {};
  autoFocus?: boolean;
  showDropdown?: boolean;
  onSelect: (record: Record) => void;
  onFocus?: () => void;
  onChange: (value: string) => void;
  setSearch?: (value: string) => void;
  handleSearch: (e: any) => void;
  inputFontColor?: string;
  inputBorderColor?: string;
  inputFontSize?: string;
  inputHeight?: string;
  inputBackgroundColor?: string;
  dropdownHoverColor?: string;
  dropdownBorderColor?: string;
  clearOnSelect?: boolean;
  leftIcon?: ReactNode;
  iconBoxSize?: number | string;
  type?: string;
}

function useOutsideAlerter(ref, setDropdownVisibility, setValue, setSearch) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownVisibility(false);
        setValue("");
        setSearch("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ReactSearchBox: FC<IProps> = ({
  placeholder = "",
  data = [],
  fuseConfigs,
  autoFocus = false,
  onSelect,
  onFocus,
  onChange,
  setSearch,
  handleSearch,
  showDropdown,
  inputBackgroundColor = "#fff",
  inputFontColor = "#000",
  inputBorderColor = "#cacaca96",
  inputFontSize = "14px",
  inputHeight = "40px",
  dropdownHoverColor = "#ccc",
  dropdownBorderColor = "#cacaca96",
  clearOnSelect = false,
  leftIcon,
  iconBoxSize = "24px",
  type = "text",
}) => {
  const [matchedRecords, setMatchedRecords] = useState<any>([]);
  const [value, setValue] = useState<string>("");
  const [showDropdownVisibility, setDropdownVisibility] = useState<boolean>(showDropdown);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setDropdownVisibility(showDropdown);
  }, [showDropdown]);

  useOutsideAlerter(wrapperRef, setDropdownVisibility, setValue, setSearch);

  const defaultFuseConfigs = {
    threshold: 0.1,
    includeMatches: true,
    location: 0,
    distance: 1000,
    minMatchCharLength: 2,
    keys: ["value"],
  };

  const configs = Object.assign({}, defaultFuseConfigs, fuseConfigs);
  const fuse = new Fuse(data, configs);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const matchedRecords = fuse.search(value);
    setValue(value);
    setMatchedRecords(matchedRecords);
    setDropdownVisibility(true);
    !!onChange && onChange(value);
  };

  const inputNode = () => {
    return (
      <InputBox
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        autoFocus={autoFocus ? autoFocus : false}
        onFocus={onFocus ? onFocus : undefined}
        $inputFontColor={inputFontColor}
        $inputBorderColor={inputBorderColor}
        $inputFontSize={inputFontSize}
        $inputHeight={inputHeight}
        $inputBackgroundColor={inputBackgroundColor}
        $leftIcon={leftIcon}
        $iconBoxSize={iconBoxSize}
        type={type}
      />
    );
  };

  const handleDropdownItemClick = (record: Record) => {
    if (clearOnSelect) {
      setValue("");
      setSearch("");
    } else {
      setValue(record.item.value);
    }
    setDropdownVisibility(false);
    !!onSelect && onSelect(record);
  };

  return (
    <div ref={wrapperRef}>
      <StyledContainer>
        <GlobalStyle />
        {inputNode()}
        {showDropdownVisibility && (
          <Dropdown
            matchedRecords={matchedRecords}
            onClick={handleDropdownItemClick}
            handleSearch={(e) => {
              setDropdownVisibility(false);
              handleSearch(e);
            }}
            $dropdownHoverColor={dropdownHoverColor}
            $dropdownBorderColor={dropdownBorderColor}
          />
        )}
      </StyledContainer>
    </div>
  );
};

export default ReactSearchBox;
