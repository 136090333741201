import React from "react";
import Image from "next/image";

const PageBannerLoading = ({ pageTitle, error, disabled }) => {
  return (
    <>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>{pageTitle}</h2>
          </div>
        </div>
      </div>
      {!disabled && (
        <>
          {!error ? (
            <div style={styles.loadingImage}>
              <Image width={130} height={43} src={`/images/loading.svg`} alt="logo" />
            </div>
          ) : (
            <div style={styles.loadingImage}>
              <Image width={130} height={43} src={`/images/error.svg`} alt="logo" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PageBannerLoading;

const styles = {
  loadingImage: { display: "flex", justifyContent: "center", alignItems: "center" },
};
