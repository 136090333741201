import React from "react";
import Router from "next/router";
import ReactSearchBox from "@/lib/react-search-box";
import useState from "react-usestateref";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

function removeDuplicates(arr, key) {
  const seen = new Set();
  return arr.reduce((acc, item) => {
    const normalized = item[key].trim().toLowerCase();
    if (!seen.has(normalized)) {
      seen.add(normalized);
      acc.push(item);
    }
    return acc;
  }, []);
}

const SearchForm = ({ courses, teachers }) => {
  const [search, setSearch, searchRef] = useState("");
  const [showDropdown, setShowDropdown] = React.useState(true);
  const [display, setDisplay] = React.useState(false);

  const profileIds = React.useMemo(() => {
    return teachers.reduce((acc, teacher) => {
      acc[teacher.profileId] = teacher;
      return acc;
    }, {});
  }, [teachers]);

  const results = React.useMemo(() => {
    const uniqueCourses = removeDuplicates(courses, "title");
    const courseResults = uniqueCourses.map((course) => ({
      key: course.title.toLowerCase(),
      value: course.title.toLowerCase(),
      id: course.id,
    }));

    const teacherResults = Object.keys(profileIds).map((id) => ({
      key: id,
      value: profileIds[id].name,
      id: id,
      isProfile: true,
      profilePhoto: profileIds[id].profilePhoto,
    }));

    return [...courseResults, ...teacherResults];
  }, [courses, profileIds]);

  const clickEnterListener = (event) => {
    if (event.key === "Enter") {
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      document.querySelector("#search-button").dispatchEvent(clickEvent);
    }
  };

  React.useEffect(() => {
    setDisplay(true);
    document.addEventListener("keyup", clickEnterListener);
    return () => {
      document.removeEventListener("keyup", clickEnterListener);
    };
  }, []);

  const handleOnChange = (value) => {
    setSearch(value);
    setShowDropdown(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchRef.current !== "") {
      setShowDropdown(false);
      setSearch("");
      Router.push({
        pathname: "/search",
        query: { q: search },
      });
    }
  };

  const handleSelect = (record) => {
    if (!record.item.isProfile) {
      Router.push({ pathname: "/search", query: { q: `${record.item.value}` } });
    } else {
      Router.push({ pathname: `/user/profile/${record.item.id}`, query: { s: `${Date.now()}` } });
    }
  };

  return (
    display && (
      <div className="search-box-container">
        <ReactSearchBox
          placeholder="Search"
          value=""
          onSelect={handleSelect}
          handleSearch={handleSearch}
          onChange={handleOnChange}
          data={results}
          leftIcon={
            <SearchRoundedIcon id="search-button" sx={{ cursor: "pointer", color: "#606060" }} onClick={handleSearch} />
          }
          showDropdown={showDropdown}
          iconBoxSize={"30px"}
          inputBackgroundColor={"#b163f121"}
          clearOnSelect
          setSearch={setSearch}
          onFocus={() => window.scrollTo(0, 0)}
        />
      </div>
    )
  );
};

export default SearchForm;
