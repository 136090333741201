import styled from "styled-components";

export const StyledDropdown = styled.div<{
  $dropdownHoverColor: string;
  $dropdownBorderColor: string;
}>`
  margin: 10px 0 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 0, rgba(0, 0, 0, 0.1) 0px 4px 11px;
  border-radius: 1em;
  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    > li {
      padding: 10px 20px;
      background-color: #fff;
      border: 1px solid ${({ $dropdownBorderColor }) => $dropdownBorderColor};
      display: flex;
      align-items: center;
      &:hover {
        background-color: ${({ $dropdownHoverColor }) => $dropdownHoverColor};
        cursor: pointer;
      }
      &:first-child {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
      }
      &:last-child {
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
      }
      &:not(:first-child) {
        border-top: 0;
      }
    }
  }
`;
