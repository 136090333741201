import Head from "next/head";

const HeadManager = ({ pathname }) => {
  const getHeadTags = () => {
    switch (pathname) {
      case "/course/[id]":
        //Override default in route
        return <></>;
      case "/authentication":
        return (
          <Head>
            <title>Login</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta key="ogTitle" name="og:title" property="og:title" content="Login"></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/authentication" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />

            <meta
              name="twitter:card"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            ></meta>
            <meta name="twitter:title" content="Login" />
            <meta property="twitter:url" content="https://coursepage.com/authentication" />

            <meta
              name="twitter:description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      case "/become-a-teacher":
        return (
          <Head>
            <title>Register as an Instructor</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="Register as an instructor and start marketing your courses online with complete control of your content, pricing, and schedule."
            />
            <meta key="ogTitle" name="og:title" property="og:title" content="Register as an Instructor"></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="Register as an instructor and start marketing your courses online with complete control of your content, pricing, and schedule."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/become-a-teacher" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/teacher-register-bg.jpg" />

            <meta
              name="twitter:card"
              content="Register as an instructor and start marketing your courses online with complete control of your content, pricing, and schedule."
            ></meta>
            <meta property="twitter:url" content="https://coursepage.com/become-a-teacher" />
            <meta name="twitter:title" content="Register as an Instructor" />
            <meta
              name="twitter:description"
              content="Register as an instructor and start marketing your courses online with complete control of your content, pricing, and schedule."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/teacher-register-bg.jpg" />
          </Head>
        );
      case "/contact":
        return (
          <Head>
            <title>Contact Us</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="Reach out to us with any concerns, feedback or questions, we will do our best to follow up
                    promptly."
            />
            <meta key="ogTitle" name="og:title" property="og:title" content="Contact Us"></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="Reach out to us with any concerns, feedback or questions, we will do our best to follow up
                    promptly."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/contact" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />
            <meta property="twitter:url" content="https://coursepage.com/contact" />
            <meta name="twitter:card" content="Contact Us."></meta>
            <meta name="twitter:title" content="Contact Us" />
            <meta
              name="twitter:description"
              content="Reach out to us with any concerns, feedback or questions, we will do our best to follow up
                    promptly."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      case "/about":
        return (
          <Head>
            <title>About Coursepage</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="An educational content marketplace for selling video courses and live classes."
            />
            <meta key="ogTitle" name="og:title" property="og:title" content="About Coursepage"></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="An educational content marketplace for selling video courses and live classes."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/about" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />
            <meta property="twitter:url" content="https://coursepage.com/about" />
            <meta name="twitter:card" content="About Coursepage"></meta>
            <meta name="twitter:title" content="About Coursepage" />
            <meta
              name="twitter:description"
              content="An educational content marketplace for selling video courses and live classes."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      case "/faq":
        return (
          <Head>
            <title>FAQs</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="Get the answers you need to start selling and marketing your courses on Coursepage."
            />
            <meta key="ogTitle" name="og:title" property="og:title" content="FAQs"></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="Get the answers you need to start selling and marketing your courses on Coursepage."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/faq" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />
            <meta property="twitter:url" content="https://coursepage.com/faq" />
            <meta name="twitter:card" content="FAQs"></meta>
            <meta name="twitter:title" content="FAQs" />
            <meta
              name="twitter:description"
              content="Get the answers you need to start selling and marketing your courses on Coursepage."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      case "/search":
        return (
          <Head>
            <title>Search</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta key="description" name="description" content="Find courses by topic or expertise." />
            <meta key="ogTitle" name="og:title" property="og:title" content="Search"></meta>
            <meta key="ogDescription" property="og:description" content="Find courses by topic or expertise." />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/search" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />
            <meta property="twitter:url" content="https://coursepage.com/search" />
            <meta name="twitter:card" content="Search"></meta>
            <meta name="twitter:title" content="Search" />
            <meta name="twitter:description" content="Find courses by topic or expertise." />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      case "/courses":
        return (
          <Head>
            <title>Courses</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta key="description" name="description" content="Explore courses from working professionals." />
            <meta key="ogTitle" name="og:title" property="og:title" content="Courses"></meta>
            <meta key="ogDescription" property="og:description" content="Explore courses from working professionals." />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com/courses" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />
            <meta property="twitter:url" content="https://coursepage.com/courses" />
            <meta name="twitter:card" content="Courses"></meta>
            <meta name="twitter:title" content="Courses" />
            <meta name="twitter:description" content="Explore courses from working professionals." />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
      default:
        return (
          <Head>
            <title>Coursepage - Connecting Learners With Educators</title>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta
              key="description"
              name="description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta
              key="ogTitle"
              name="og:title"
              property="og:title"
              content="Coursepage - Connecting Learners With Educators"
            ></meta>
            <meta
              key="ogDescription"
              property="og:description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta key="ogUrl" property="og:url" content="https://coursepage.com" />
            <meta key="ogImage" property="og:image" content="https://coursepage.com/images/logo-wide.png" />

            <meta
              name="twitter:card"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            ></meta>
            <meta name="twitter:title" content="Coursepage - Connecting Learners With Educators" />
            <meta property="twitter:url" content="https://coursepage.com" />
            <meta
              name="twitter:description"
              content="Coursepage is an educational content marketplace that facilitates the monetization of knowledge through the sale of mentorship sessions, video courses, and live classes."
            />
            <meta name="twitter:image" content="https://coursepage.com/images/twitter-wide.png" />
          </Head>
        );
    }
  };

  return getHeadTags();
};

export default HeadManager;
