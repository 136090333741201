import React from "react";
import Link from "next/link";
import Image from "next/image";

const Custom404 = () => {
  return (
    <div className="error-area ptb-100">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="error-content">
              <Image width={500} height={198} src="/images/error.png" alt="image" />
              <h3>Error 404 : Page Not Found</h3>
              <p>
                The page you are looking for might have been removed had its name changed or is temporarily unavailable.
              </p>

              <div className="btn-box">
                <Link legacyBehavior href="/">
                  <a className="default-btn">
                    Homepage <span></span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom404;
