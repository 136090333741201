import { FC, ReactNode } from "react";
import { StyledDropdown } from "./styles";
import { convertHtmlToReact } from "@hedgedoc/html-to-react";

interface IProps {
  onClick: any;
  handleSearch: any;
  matchedRecords: [
    {
      item: {
        key: string;
        value: string;
      };
    }
  ];
  $dropdownHoverColor: string;
  $dropdownBorderColor: string;
}

function buildResult(value: string, tupleArr: number[][]): ReactNode {
  let result = value;
  let maxRange = -1;
  let maxTuple = [];
  for (let tuple of tupleArr) {
    if (tuple[1] - tuple[0] > maxRange) {
      maxRange = tuple[1] - tuple[0];
      maxTuple = tuple;
    }
  }
  let start = maxTuple[0];
  let end = maxTuple[1];
  result = result.slice(0, start) + "<b>" + result.slice(start, end + 1) + "</b>" + result.slice(end + 1);
  return <div style={{ wordBreak: "break-word" }}>{convertHtmlToReact(result)}</div>;
}
const Dropdown: FC<IProps> = ({
  onClick,
  matchedRecords = [],
  $dropdownBorderColor,
  $dropdownHoverColor,
  handleSearch,
}) => {
  return (
    <StyledDropdown
      className="react-search-box-dropdown"
      $dropdownHoverColor={$dropdownHoverColor}
      $dropdownBorderColor={$dropdownBorderColor}
    >
      <ul>
        {matchedRecords
          .map((record) => {
            return (
              <li key={record.item.key} className="react-search-box-dropdown-list-item" onClick={() => onClick(record)}>
                {record.item.isProfile && (
                  <img
                    src={`${record.item.profilePhoto ? record.item.profilePhoto : "/images/advisor/advisor3.svg"}`}
                    className="search-image rounded-circle"
                    alt=""
                  />
                )}
                {buildResult(record.item.value, record.matches[0].indices)}
              </li>
            );
          })
          .slice(0, 7)}
        {matchedRecords.length > 0 && (
          <li key={"show-all"} className="react-search-box-dropdown-list-item" onClick={(e) => handleSearch(e)}>
            <span style={{ margin: "auto", fontWeight: "700" }}>Show All Results</span>
          </li>
        )}
      </ul>
    </StyledDropdown>
  );
};

export default Dropdown;
