import React from "react";
import { createRoot } from "react-dom/client";
import ReactModal from "react-modal";
import { signOut } from "next-auth/react";
import baseUrl from "@/utils/baseUrl";
import * as Sentry from "@sentry/nextjs";

const getErrorMessage = (error) => {
  return (
    error.response?.data?.error?.message ||
    error.response?.data?.message ||
    error.response?.data ||
    error?.request ||
    error?.message ||
    "An unexpected error occurred, please try again later."
  );
};

const showErrorToast = (errorMsg, toastError) => {
  const shortErrorMsg = errorMsg.slice(0, 250);
  const toastId = shortErrorMsg.replace(/\W/g, "") || "custom-error";
  toastError(shortErrorMsg, { toastId: toastId });
};

const showSessionExpiredModal = () => {
  const div = document.createElement("div");
  document.body.appendChild(div);

  const root = createRoot(div);

  const handleCloseModal = () => {
    root.unmount();
    document.body.removeChild(div);
  };

  const handleConfirm = () => {
    handleCloseModal();
    signOut({ callbackUrl: `${baseUrl}/authentication` });
  };

  root.render(<SessionExpiredModal onConfirm={handleConfirm} />);
};

const SessionExpiredModal = ({ onConfirm }) => (
  <ReactModal
    className="ReactModal__Content"
    overlayClassName="ReactModal__Overlay"
    isOpen={true}
    shouldCloseOnOverlayClick={false}
  >
    <h2>Your session has expired</h2>
    <p>Please log in to continue.</p>
    <button style={{ width: "auto" }} className="secondary-btn" onClick={onConfirm}>
      Log in
    </button>
  </ReactModal>
);

const catchErrors = (error, toastError) => {
  const errorMsg = getErrorMessage(error);
  Sentry.captureException(error);

  if ([401, 403].includes(error.response?.status)) {
    showSessionExpiredModal();
    return;
  }

  showErrorToast(errorMsg, toastError);
};

export default catchErrors;
